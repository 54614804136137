import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  Environment,
  FontSizes,
  Logger,
} from 'common';

import {
  Button,
  H2,
  Text,
} from 'common/components';

import {
  EventMobileTicketSummary
} from 'ui/event/components';

import {
  withCommon,
} from 'common/hocs';

import {
  OrderProvider
} from 'providers';

import {
  MaxWidthContainer
} from 'ui/event/styled';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 80vh;
  padding: 60px 0;
`;

const SummaryContainer = styled.div`
  background-color: ${Colors.GreyBrown};
  border-radius: 10px;
  overflow: hidden;
`;

const EventPaymentResultUI = props => {

  const [state, setState] = React.useState({
    icon: undefined,
    title: '',
    textLine1: '',
    textLine2: '',
    textLine3: '',
    paymentRef: '',
    buttonText: '',
    buttonUrl: '',
    downloadToken: ''
  });
  const [selectedTickets, setSelectedTickets] = React.useState(null);

  const isErrorState = props.common.pathParams.result === 'error' || props.common.pathParams.result === 'cancelled';

  const onDownloadClick = async () => {

    props.common.showLoading()

    const searchParams = new URLSearchParams(props.common.location.search);
    const orderRef = searchParams.get('order_id');

    try {
      const response = await fetch(`${Environment.apiHost}payment/download-order`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: state.downloadToken
          }),
      });

      if (!response.ok) {
          throw new Error('Failed to download PDF');
      }

      // Create a blob from the response
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${orderRef}.pdf`); // Name of the downloaded file
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      props.common.showToast('Oh no, something went wrong, please try again.');
      Logger.error('EventPaymentResult', `onDownloadClick (${orderRef})`, error)
    }
    finally {
      props.common.hideLoading()
    }
  };

  const load = async () => {

    if (!props.common?.pathParams?.result) {

      props.common.showToast('Oh no, something went wrong, please try again.');
      return;
    }

    const searchParams = new URLSearchParams(props.common.location.search);
    const orderRef = searchParams.get('order_id');

    if (props.common.pathParams.result === 'success') {

      setState({
        icon: (
          <div style={{ width: '120px', height: '120px', padding: '15px 10px 5px 10px', marginBottom: '5px' }}>
            <svg style={{ width: '100%', height: '100%' }} xmlns="http://www.w3.org/2000/svg" width="33.624" height="33.041" viewBox="0 0 33.624 33.041">
              <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(-1.497 -1.473)">
                <path id="Path_1017" data-name="Path 1017" d="M33,16.62V18A15,15,0,1,1,24.105,4.29" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                <path id="Path_1018" data-name="Path 1018" d="M33,6,18,21.015l-4.5-4.5" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
              </g>
            </svg>
          </div>
        ),
        title: 'Payment Successful!',
        textLine1: 'We are preparing your tickets, they will be in your inbox soon.',
        textLine2: 'Note, this process might take up to 10 minutes.',
        textLine3: '',
        paymentRef: orderRef,
        buttonText: 'Home',
        buttonUrl: '/',
        downloadToken: searchParams.get('token')
      });
    }

    if (props.common.pathParams.result === 'error' || props.common.pathParams.result === 'cancelled') {
      
      setState({
        icon: (
          <svg style={{ width: '150px', height: '150px' }} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <g clipPath="url(#clip0_1567_876)">
            <path d="M18 6C15.6266 6 13.3066 6.70379 11.3332 8.02236C9.35977 9.34094 7.8217 11.2151 6.91345 13.4078C6.0052 15.6005 5.76756 18.0133 6.23058 20.3411C6.6936 22.6689 7.83649 24.8071 9.51472 26.4853C11.193 28.1635 13.3312 29.3064 15.6589 29.7694C17.9867 30.2324 20.3995 29.9948 22.5922 29.0866C24.7849 28.1783 26.6591 26.6402 27.9776 24.6668C29.2962 22.6935 30 20.3734 30 18C30 14.8174 28.7357 11.7652 26.4853 9.51472C24.2349 7.26428 21.1826 6 18 6ZM18 28C16.0222 28 14.0888 27.4135 12.4443 26.3147C10.7998 25.2159 9.51809 23.6541 8.76121 21.8268C8.00433 19.9996 7.8063 17.9889 8.19215 16.0491C8.578 14.1093 9.53041 12.3275 10.9289 10.9289C12.3275 9.53041 14.1093 8.578 16.0491 8.19215C17.9889 7.8063 19.9996 8.00433 21.8268 8.7612C23.6541 9.51808 25.2159 10.7998 26.3147 12.4443C27.4135 14.0888 28 16.0222 28 18C28 19.3132 27.7413 20.6136 27.2388 21.8268C26.7363 23.0401 25.9997 24.1425 25.0711 25.0711C24.1425 25.9997 23.0401 26.7362 21.8268 27.2388C20.6136 27.7413 19.3132 28 18 28Z" fill="#ffffff"/>
            <path d="M17.9992 20.0707C17.6544 20.0707 17.3238 19.9337 17.08 19.6899C16.8362 19.4461 16.6992 19.1155 16.6992 18.7707V12.7707C16.6992 12.4259 16.8362 12.0953 17.08 11.8515C17.3238 11.6077 17.6544 11.4707 17.9992 11.4707C18.344 11.4707 18.6747 11.6077 18.9185 11.8515C19.1623 12.0953 19.2992 12.4259 19.2992 12.7707V18.7707C19.2992 19.1155 19.1623 19.4461 18.9185 19.6899C18.6747 19.9337 18.344 20.0707 17.9992 20.0707Z" fill="#ffffff"/>
            <path d="M17.9492 24.5195C18.7776 24.5195 19.4492 23.848 19.4492 23.0195C19.4492 22.1911 18.7776 21.5195 17.9492 21.5195C17.1208 21.5195 16.4492 22.1911 16.4492 23.0195C16.4492 23.848 17.1208 24.5195 17.9492 24.5195Z" fill="#ffffff"/>
            </g>
            <defs>
            <clipPath id="clip0_1567_876">
              <rect width="36" height="36" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        ),
        title: 'Payment Error',
        textLine1: 'Oops! Something went wrong while processing your payment.',
        textLine2: 'We apologize for the inconvenience.',
        paymentRef: orderRef,
        buttonText: 'Retry payment',
        buttonUrl: `/event/payment?retry=true&orderId=${orderRef?.replace('WT-', '')}`
      });
    }

    OrderProvider.getOrderByOrderId(orderRef?.replace('WT-', ''), props.common.pathParams.result)
      .then(ret => {
        if (ret.ok &&
          (Object.keys(ret.data?.selectedTickets || {}).length > 0 || Object.keys(ret.data?.selectedMerch || {}).length  > 0)
        ) {
          setSelectedTickets(ret.data);
        }
        else {
          Logger.error('EventPaymentResult', 'OrderProvider.getOrderByOrderId', `Unable to load order after payment for order ${orderRef}`);
        }
      })
      .catch(e => Logger.error('EventPaymentResult', `OrderProvider.getOrderByOrderId (${orderRef})`, e))
      .finally(() => props.common.hideLoading());
  }

  React.useEffect(() => {
    
    load()
      .catch(e => Logger.error('EventPaymentResult', 'load', e)); // TODO: show error and log it
  }, []); // eslint-disable-line
  
  return (

    <Container>
      {state.icon}
      <H2>{state.title}</H2>
      <Text padding={'0 15px'} textAlign={'center'}>{state.textLine1}</Text>
      { state.textLine2 && <Text padding={'0 15px'} textAlign={'center'}>{state.textLine2}</Text> }
      { state.textLine3 && <Text padding={'0 15px'} textAlign={'center'}>{state.textLine3}</Text> }
      <Text padding={'15px 15px 15px'} textAlign={'center'}><b>Order ref: {state.paymentRef}</b></Text>

      <MaxWidthContainer>
        { !isErrorState && state.downloadToken && selectedTickets?.status === 'paid' &&
          <>
          <SummaryContainer>
            <Text
              fontSize={FontSizes.Small}
              padding={'15px'}
              textAlign={'center'}>
                You also have the option to download your tickets directly by selecting this <Text fontSize={FontSizes.Small} textDecoration={'underline'} onClick={onDownloadClick}>download link</Text>.
            </Text>
          </SummaryContainer>
          <div style={{ height: '15px' }} />
          </>
        }

        { !!selectedTickets &&

          <SummaryContainer>
            <EventMobileTicketSummary
                totals={selectedTickets}
                hideCheckoutButton={true}>

                <Button
                  text={state.buttonText ?? 'Home'}
                  width={'100%'}
                  onClick={() => props.common.navigate(state.buttonUrl ?? '/')}
                />
              </EventMobileTicketSummary>
            </SummaryContainer>
          }

          { !selectedTickets &&
          
            <Button
              text={state.buttonText ?? 'Home'}
              width={'100%'}
              onClick={() => props.common.navigate(state.buttonUrl ?? '/')}
            />
          }

          { isErrorState &&

            <Text
              textAlign={'center'}
              width={'100%'}
              fontSize={FontSizes.Small}
              padding={'10px 0'}
              textDecoration={'underline'}
              onClick={() => props.common.navigate('/')}>

              Go Home
            </Text>
          }
        </MaxWidthContainer>
    </Container>
  );
};

export const EventPaymentResult = withCommon(EventPaymentResultUI);